import React, { useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import Bar from './Bar';
import Footer from './Footer';
import appmockup from './img/appmockup.png';
import construction from './img/construction.png';
import appmockup2 from './img/appmockup2.png';

// SVGS
import cloudlock from './img/svgs/cloudlock.svg';
import deskclock from './img/svgs/deskclock.svg';
import layers from './img/svgs/layers.svg';
import like from './img/svgs/like.svg';
import stats from './img/svgs/stats.svg';
import excel from './img/svgs/excel.svg';


import quoteopening from './img/quoteopening.png';
import quoteclosing from './img/quoteclosing.png';
import firstperson from './img/firstperson.png';
import secondperson from './img/secondperson.png';
import thirdperson from './img/thirdperson.png';

import { getAnalytics, logEvent } from 'firebase/analytics';



function App() {
  function login() {
    const analytics = getAnalytics();
    logEvent(analytics, 'try-free-button-clicked');  

    window.open('https://forms.gle/ZkoYF2NbbtYCDbMe7', '_blank');
  }

  function pricing() {
    const analytics = getAnalytics();
    logEvent(analytics, 'pricing-button-clicked');  

    window.open('/cennik', '_self');
  }

  const location = useLocation();
  const functionalitiesRef = useRef(null);
  const forWhoRef = useRef(null);


  useEffect(() => {
    function scrollToSection() {
      const hash = location.hash;
      if (hash && hash === "#functionalities" && functionalitiesRef.current) {
        functionalitiesRef.current.scrollIntoView({ behavior: 'instant' });
      }
    }

    scrollToSection(); // Call scrollToSection on mount and when location changes
  }, [location]);

  useEffect(() => {
    function scrollToSection() {
      const hash = location.hash;
      if (hash && hash === "#dla-kogo" && forWhoRef.current) {
        forWhoRef.current.scrollIntoView({ behavior: 'instant' });
      }
    }

    scrollToSection(); // Call scrollToSection on mount and when location changes
  }, [location]);

  

  return (
    <div className="App">
      <Bar />
      
      <div className='first'>
        <div className='left'>
          <p className='body5 blue-body'>ZAOSZCZĘDŹ CZAS I PIENIĄDZE</p>
          <h3>Rozwiąż problem ewidencji godzin pracowniczych raz na zawsze.</h3>
          <p  className='body5'>Zapomnij o excelu, papierowych listach i wysyłaniu maili. </p>
          <button className='button-default' onClick={login}>Skorzystaj za darmo</button>
        </div>
        <div className='right'>
          <img src={appmockup} alt='app mockup' />
        </div>
      </div>

      <div className='second'>
        <div className='left'>
          <img src={construction} alt='people on construction' />
        </div>
        <div className='right'>
          <p className='body5 blue-body'>WYGODNIE Z KAŻDEGO MIEJSCA</p>
          <h3>Dla pracowników</h3>
          <h3> stacjonarnych i terenowych</h3>
          <p className='body5'>Z domu, biura, czy placu budowy - dostęp do systemu jest tak samo prosty.</p>
        </div>
      </div>

      <div className='third'>
        <div className='left'>
            <p className='body5 blue-body'>BEZCENNE DANE DLA ZARZĄDU</p>
            <h3>Analiza rentowności projektów</h3>
            <p className='body5'>Każda godzina pracy to koszt dla firmy. Dowiedz się, ile godzin pochłonęły konkretne projekty i lepiej wyceniaj w przyszłości.</p>
          </div>
        <div className='right'>
          <img src={appmockup2} alt='app mockup' />
        </div>
      </div>

      <div className='functionalities' id='functionalities' ref={functionalitiesRef}>
        <h3>Poznaj funkcjonalności</h3>
        <div className='cards'>
          <div className='card'>
            <img src={deskclock} alt='deskclock' />
            <h4>Raportowanie godzin</h4>
            <p className='body5'>Umożliwia pracownikom rejestrowanie godzin pracy w prosty i wygodny sposób.</p>
          </div>

          <div className='card'>
            <img src={layers} alt='layers' />
            <h4>Podsumowania</h4>
            <p className='body5'>Zestawienia przepracowanych godzin, aby łatwo wyliczyć wynagrodzenia.</p>
          </div>

          <div className='card'>
            <img src={stats} alt='stats' />
            <h4>Analityka</h4>
            <p className='body5'>Dowiedz się, które zadania i projekty pochłaniają najwięcej czasu, aby lepiej zarządzać firmą.</p>
          </div>
        </div>
        <div className='cards'>
          <div className='card'>
            <img src={cloudlock} alt='cloudlock' />
            <h4>Bezpieczeństwo danych</h4>
            <p className='body5'>Twoje dane zawsze bezpieczne.</p>
          </div>

          <div className='card'>
            <img src={like} alt='like' />
            <h4>Intuicyjność</h4>
            <p className='body5'>Łatwa obsługa dla wszystkich użytkowników.</p>
          </div>

          <div className='card'>
            <img src={excel} alt='excel' />
            <h4>Export do excela</h4>
            <p className='body5'>Czas pracy pracowników, zestawienia projektów do excela i PDF</p>
          </div>
        </div>
      </div>

      <div className='quote-section'>
        <div className='quote'>
          <img src={quoteopening} alt='quote opening' />
          <p className='body4'>Wprowadzenie systemu pozwoliło na <b>zaoszczędzenie czasu</b>, bo nie są już zbierane karty pracy w formie papierowej, potem przepisywane, analizowane <br className='display-on-mobile'/>i przekazywane do naliczeń.</p>
          <img src={quoteclosing} alt='quote closing' />
        </div>

        <div className='quote-author'>
          <h5 className='name'>Maciej P.</h5>
          <h5 className='role'>Prezes Zarządu</h5>
          <h5 className='company'>Verisnet sp. z.o.o.</h5>
        </div>        
      </div>

      <div className='quote-section'>
        <div className='quote'>
          <img src={quoteopening} alt='quote opening' />
          <p className='body4'>Oszczędność czasu, <b>szybka weryfikacji obecności i przepracowanych godzin.</b><br/>Niezbędne narzędzie do realizacji wymagających projektów w naszej firmie!</p>
          <img src={quoteclosing} alt='quote closing' />
        </div>

        <div className='quote-author'>
          <h5 className='name'>Piotr Kuklis</h5>
          <h5 className='role'>Prezes Zarządu</h5>
          <h5 className='company'>Petrostal sp. z o.o.</h5>
        </div>

        <div className='quote-button'>
          <button className='button-default' onClick={login}>Rozpocznij darmowy okres próbny</button>
        </div>          
      </div>

      <div className='for-who' id='dla-kogo' ref={forWhoRef}>
        <h3>Dla kogo?</h3>
        <div className='cards'>

          <div className='card'>
            <img src={firstperson} alt='first person' />
            <h4>Praca Stacjonarna</h4>
            <ul>
              <li className='body5'>Sprawdzi się w biurze czy magazynie.</li>
              <li className='body5'>Koniec kartek z ewidencją godzin.</li>
            </ul>
          </div>

          <div className='card'>
            <img src={secondperson} alt='second person' />
            <h4>Praca w terenie</h4>
            <ul>
              <li className='body5'>Dla firm pracujących nad instalacjami lub montażami.</li>
              <li className='body5'>Widzisz kto i ile przepracował nad konkretnym projektem.</li>
              <li className='body5'>Łatwiej wycenisz kolejne projekty.</li>
            </ul>
          </div>

          <div className='card'>
            <img src={thirdperson} alt='third person' />
            <h4>Praca zdalna</h4>
            <ul>
              <li className='body5'>Część pracowników pracuje z domu?</li>
              <li className='body5'>Łatwy dostęp do systemu z każdego miejsca!</li>
            </ul>
          </div>

        </div>
      </div>

      <div className='try'>
        <h3>Wypróbuj system przez miesiąc<br className='display-on-mobile'/> za darmo!</h3>
        <p className='body5'>Bez podawania karty płatniczej</p>
          <button className='button-yellow' onClick={pricing}>Sprawdź cennik</button>
          <button className='button-default' onClick={login}>Skorzystaj za darmo</button>
      </div>

      <Footer />
    </div>
  );
}

export default App;
