import React, { useEffect, useLayoutEffect} from 'react';
import logo from './img/logo.png';
import { Link, useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from 'firebase/analytics';



function Bar() {

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const location = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);


  useEffect(() => {
    function scrollFunction() {
      const navbar = document.getElementsByClassName("menu-wrapper")[0];
      const sticky = navbar.offsetTop;

      if (window.scrollY >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }

    window.addEventListener('scroll', scrollFunction);

    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);

  function login() {
    const analytics = getAnalytics();
    logEvent(analytics, 'login-button-clicked');  

    window.open('https://app.egodziny.pl/', '_blank');
  }

  function tryFree() {
    const analytics = getAnalytics();
    logEvent(analytics, 'try-free-button-clicked');  

    window.open('https://forms.gle/ZkoYF2NbbtYCDbMe7', '_blank');
  }

  return (
    <div className='menu-wrapper'>
      <div className="menubar">
        {/* MENU DESKTOP */}
        <Link to="/"><img src={logo} className="logo menu-item" alt="logo" onClick={scrollToTop} /></Link>
        <a href="/#functionalities" className='menu-item hidden-in-menu'>Poznaj system</a>
        <Link to="/cennik" className='menu-item hidden-in-menu' onClick={scrollToTop}>Cennik</Link>
        <a href="#kontakt" className='menu-item hidden-in-menu'>Kontakt</a>
        <button className="login button-default hidden-in-menu" onClick={login}>Zaloguj się</button>
      </div>

      {/* MENU MOBILE */}
      <input id="menu-toggle" type="checkbox"/>
      <label className='menu-button-container' htmlFor="menu-toggle">
        <div className='menu-button'></div>
      </label>
      <ul className='menu'>
        <li><a href="/#functionalities" >Poznaj system</a></li>
        <li><Link to="/cennik" onClick={scrollToTop}>Cennik</Link></li>
        <li><a href="#kontakt" >Kontakt</a></li>
        <li><button className="login button-default" onClick={tryFree}>Skorzystaj za darmo</button></li>
        <li><a href='#' onClick={login}>Zaloguj się</a></li>
      </ul>
    </div>

  );
}

export default Bar;
